.App {
  text-align: center;
  padding: 20px;
}

.App-header {
  margin-bottom: 20px;
}

.Screenshots {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.Screenshots img {
  width: 30%;
  margin-bottom: 20px;
}

.Functions {
  margin-bottom: 20px;
}

.Functions h2 {
  font-size: 24px;
  font-weight: 700;
  color: #555;
  margin-bottom: 10px;
}

.Functions ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.Functions div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.Functions div p {
  font-size: 18px;
  font-weight: 400;
  color: #555;
}

.Support {
  margin-top: 20px;
}

.Support h2 {
  font-size: 24px;
  font-weight: 700;
  color: #555;
  margin-bottom: 10px;
}

.Support p {
  font-size: 18px;
  font-weight: 700;
  color: #555;
  margin-bottom: 10px;
}

.Support ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.Support div {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.Support div i {
  font-size: 24px;
  margin-right: 10px;
  color: #555;
}

.Support div p {
  font-size: 18px;
  font-weight: 700;
  color: #555;
}
